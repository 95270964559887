.App {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
}
.match {
  margin-top: 40px;
}

.navlink {
  margin: 10px 5px;
  font-size: 20px;
  font-weight: bold;
  color: '#0096FF';
}
.address {
  width: 90%;
  height: 200px;
  margin: 10px 10px 10px 10px;
  resize: none;
}
.map {
  height: 500px;
  width: 90%;
  margin: 5px 10px;
}

.summary {
  margin: 5px 10px;
}
